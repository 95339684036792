import { TextMention } from '@app/core/models/project';
import { marker as _ } from '@biesbjerg/ngx-translate-extract-marker';
import Quill from 'quill';
import QuillImageDropAndPaste from 'quill-image-drop-and-paste';
import ImageResize from 'quill-image-resize-module';
import 'quill-mention';
import { StorageEnum } from '../enums/storage';
import { breakDown, fixUp } from './fix-list';

const FontStyle = Quill.import('attributors/style/font');
const AlignStyle = Quill.import('attributors/style/align');
const SizeStyle = Quill.import('attributors/style/size');
const InlineCode = Quill.import('formats/code');
const Embed = Quill.import('blots/embed');

export const refCheckBlotName = 'ref-check';
export const footnoteBlotName = 'footnote';

class RefCheck extends Embed {
  static blotName = refCheckBlotName;
  static className = refCheckBlotName;
  static tagName = 'span';

  static create(value: Record<'id' | 'text', string>) {
    const node = super.create();

    node.setAttribute('id', value.id);
    node.innerText = value.text;

    return node;
  }

  static value(node: HTMLElement) {
    return {
      id: node.getAttribute('id'),
      text: node.innerText,
    };
  }
}

class Footnote extends Embed {
  static blotName = footnoteBlotName;
  static className = footnoteBlotName;
  static tagName = 'span';

  static create(value: string) {
    const node = super.create();

    node.innerText = value;

    return node;
  }

  static value(node: HTMLElement) {
    return node.innerText;
  }
}

class PreserveWhiteSpace {
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private quill: any,
    private options: object
  ) {
    quill.container.style.whiteSpace = 'pre-line';
  }
}

Quill.register('modules/imageResize', ImageResize);
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste);
Quill.register(SizeStyle, true);
Quill.register(FontStyle, true);
Quill.register(AlignStyle, true);
Quill.register(InlineCode, true);
Quill.register(RefCheck);
Quill.register(Footnote);
Quill.register('modules/preserveWhiteSpace', PreserveWhiteSpace);

const toolbarOptions = [
  [{ font: ['monospace'] }],
  ['bold', 'italic'],
  [{ list: 'ordered' }, { list: 'bullet' }],
  ['code-block'],
  ['image'],
  ['clean'],
  [footnoteBlotName],
];

/*************** Public  *************/
export const EDITOR_MODULES = {
  toolbar: {
    container: toolbarOptions,
    handlers: {
      [footnoteBlotName]: function () {
        this.showFootnotes();
      },
    },
    showFootnotes() {},
  },
  imageResize: {
    toolbarButtonStyles: {
      visibility: 'hidden',
    },
  },
  imageDropAndPaste: {},
  preserveWhiteSpace: true,
  clipboard: {
    // More info: https://github.com/quilljs/quill/issues/1328 & https://github.com/quilljs/quill/issues/1379#issuecomment-396114612
    matchVisual: false,
  },
};

export const textTemplatePlaceholder = _(
  'Use @ to get text templates, # to get checks. Click Ctrl (or Cmd) + Z to undo.'
);

export const atKey = '@';
export const hashKey = '#';
export const mentionListClass = 'mention-list';
export const mentionItemClass = 'mention-item';

export function genEditorMentionModules(
  textTemplates: TextMention[],
  checkTextMentions: TextMention[] = []
) {
  return {
    ...EDITOR_MODULES,
    mention: {
      mentionDenotationChars: [atKey, hashKey],
      dataAttributes: ['checkId', 'field', 'title', 'display'],
      showDenotationChar: false,
      mentionListClass,
      isolateCharacter: true,
      listItemClass: mentionItemClass,
      source(searchTerm: string, renderList, mentionChar: string) {
        const selectedTextMentions =
          mentionChar === atKey ? textTemplates : checkTextMentions;

        if (searchTerm.length === 0) {
          renderList(selectedTextMentions, searchTerm);
          this.onRendered(mentionChar);

          return;
        }

        if (!selectedTextMentions || selectedTextMentions.length === 0) return;

        const matches = [];

        selectedTextMentions.forEach(data => {
          if (
            data.display.toLowerCase().indexOf(searchTerm.toLowerCase()) > -1
          ) {
            matches.push(data);
          }
        });

        renderList(matches, searchTerm);
        this.onRendered(mentionChar);
      },
      onRendered() {},
    },
  };
}

export async function setHeightForImages(content: string) {
  const element = document.createElement('div');
  element.innerHTML = content;

  const imageElements = element.querySelectorAll('img[src]');
  const imageArr = Array.from(imageElements) as HTMLImageElement[];

  for await (const img of imageArr) {
    if (!img.complete) {
      try {
        await onLoadImage(img);
      } catch {
        continue;
      }
    }

    const rate = +img['naturalWidth'] / +img['naturalHeight'];
    const widthAttr = document.createAttribute('width');
    const heightAttr = document.createAttribute('height');

    widthAttr.value = String(img['width'] || img['naturalWidth']);
    heightAttr.value = Math.round(+widthAttr.value / rate).toString();

    img.attributes.setNamedItem(widthAttr);
    img.attributes.setNamedItem(heightAttr);

    if (img.attributes.getNamedItem('style')) {
      img['style'].cursor = 'text';
    }

    // remove secure token
    const newSrcArr = img.attributes.getNamedItem('src').value.split('?');
    if (newSrcArr.length === 2) {
      img.setAttribute('src', newSrcArr[0]);
    }
  }

  replaceCodeBlockFormat(element);
  return fixUp(element.innerHTML);
}

export function removeImgHeightAttr(content: string) {
  const element = document.createElement('div');
  element.innerHTML = content || '';

  const imageElements = element.querySelectorAll('img[src]');
  const accessToken = localStorage.getItem(StorageEnum.AccessToken);

  imageElements.forEach(img => {
    if (img.attributes.getNamedItem('height')) {
      img.attributes.removeNamedItem('height');
    }

    const srcValue = img.attributes.getNamedItem('src').value;
    const urlObj = new URL(srcValue);

    urlObj.searchParams.set('secure', accessToken);
    img.setAttribute('src', urlObj.toString());
  });

  return breakDown(element.innerHTML);
}

export function replaceCodeBlockFormat(content: HTMLElement) {
  const elementCodeBlocks = content.querySelectorAll('pre[class="ql-syntax"]');

  elementCodeBlocks.forEach(item => {
    item.setAttribute(
      'style',
      'background-color:#23241f;color:#f8f8f2;overflow:visible;white-space:pre-wrap;margin:5px 0;padding:5px 10px;border-radius:3px;'
    );
  });
}

/*************** Private  *************/
function onLoadImage(img: Element): Promise<void> {
  return new Promise((resolve, reject) => {
    img.addEventListener('load', () => {
      resolve(null);
    });

    img.addEventListener('error', () => {
      reject();
    });
  });
}
